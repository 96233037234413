interface HttpApiParams {
  api: string;
  body?: Record<string, any>; // Change this to a more specific type if known
  type?: apiMethodType;
  content_type?: string;
}

export type apiMethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'form';

export interface HttpApiResponse<T = any> {
  data?: T;
  status?: number;
  original_text?: string;
  is_error?: number
}

const baseUrl = "https://admin.financemutual.com/api/admin/auth/";
let headers: any = {
  Accept: "*/*",
  "x-client-url": window.location.href,
  "x-lan-code": "en",
};

async function httpApiCall(params: HttpApiParams): Promise<any> {
  // params.body = { ...defaultBodyParams, ...params.body };
  let body: any = params.body || {};

  // headers = {...defaultBodyParams, ...headers}
  let url = baseUrl + params.api;
  headers['Content-Type'] = 'application/json';
  if (params.type === 'GET' && body) {
    body = new URLSearchParams(body).toString();
    url = `${url}?${body}`;
  } else {
    body = JSON.stringify(body);
  }
  const fetchOptions: any = {
    method: params.type,
    headers
  };

  if (params.type === 'POST' && body) {
    fetchOptions.body = body;
  }

  // Retry logic
  const maxRetries = 1;
  let attempt = 0;
  while (attempt < maxRetries) {
    try {
      const response = await fetch(url, fetchOptions);
      if (!response.ok) {
        // Handle non-2xx status codes
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const apiResponse = await response.json();

      if (apiResponse?.is_error === 1) {
        return apiResponse;
      } else {
        return apiResponse;
      }

    } catch (error: any) {
      attempt++;
      if (attempt >= maxRetries) {
        console.error("API call failed after 3 attempts:", error);
        return { is_error: 1, original_text: error?.message || error };
      }
    }
  }

  return { is_error: 1, original_text: 'Unexpected error occurred' };
}

export async function uploadFile(params: HttpApiParams): Promise<any> {
  try {
    let url = baseUrl + params.api;
    const fetchOptions: any = {
      method: params.type,
      body: params.body
    };
    const maxRetries = 1;
    let attempt = 0;
    while (attempt < maxRetries) {
      try {
        const response = await fetch(url, fetchOptions);
  
        if (!response.ok) {
          // Handle non-2xx status codes
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
      } catch (error) {
        attempt++;
        if (attempt >= maxRetries) {
          console.error('API call failed after 3 attempts:', error);
          return { is_error: 1, original_text: 'Failed to fetch data after multiple attempts' };
        }
      }
    }
  
    return { is_error: 1, original_text: 'Unexpected error occurred' };
  } catch (error) {
    return { is_error: 1, original_text: error };
  }
}

export async function downloadApi(params: HttpApiParams) {
  try {
    let url = baseUrl + params.api;
    const fetchOptions: any = {
      method: params.type,
      responseType: 'blob' as 'json', 
      observe: 'response' as 'body',
      headers
    };
  
    if (params.type === 'POST' && params.body) {
      fetchOptions.body = params.body;
    } else  if (params.type === 'GET' && params.body){
      const body = new URLSearchParams(params.body).toString();
      url = `${url}?${body}`;
    }
    let response: any = await fetch(url, fetchOptions);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export default httpApiCall;
