import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import TermsConditions from './pages/terms-conditions/terms-conditions';
import Support from './pages/support/support';
import App from './App';
import PrivacyPolicy from './pages/privacy-policy/privacy-policy';

const Routing: React.FC = () => {

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path="/" element={<App />} />
                <Route path="/terms-conditions" element={<TermsConditions />} />
                <Route path="/contact-us" element={<Support />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
            </>
        )
    );

    return <RouterProvider router={router} />;
};

export default Routing;

