import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';


interface footerProps {}

interface IFormInput {
  email: string
}

const Footer: FC<footerProps> = () => {

  const { register, handleSubmit, reset , formState: { errors }} = useForm<IFormInput>()


  async function httpApiCall(params: any): Promise<any> {
    const headers: any = {};
    // params.body = { ...defaultBodyParams, ...params.body };
    let body: any = params || {};
  
    // headers = {...defaultBodyParams, ...headers}
    let url = 'https://api.credee.com/admin/subscribe_marketing_newsletter';
    // const type = JSON.parse(JSON.stringify(params.type));
    headers['Content-Type'] = 'application/json';
    const fetchOptions: any = {
      method: 'POST'
    };
    body['recuvery_hit_from'] = 'FINANCEMUTUAL_APP';
    body = JSON.stringify(body);
    // if (type !== 'form') {
    //   fetchOptions.headers = headers;
    // }
  
    fetchOptions.body = body;
    fetchOptions.headers = headers;
    // Retry logic
    const maxRetries = 1;
    let attempt = 0;
    while (attempt < maxRetries) {
      try {
        const response = await fetch(url, fetchOptions);
  
        if (!response.ok) {
          // Handle non-2xx status codes
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        return await response.json();
      } catch (error) {
        attempt++;
        if (attempt >= maxRetries) {
          console.error('API call failed after 3 attempts:', error);
          return { is_error: 1, original_text: 'Failed to fetch data after multiple attempts' };
        }
      }
    }
  
    return { is_error: 1, original_text: 'Unexpected error occurred' };
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    httpApiCall(data);
    toast.success('Thank you for subscribing to our newsletter. We appreciate your interest and look forward to keeping you updated with our latest news and updates.',
      {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
        duration: 10000
      });
    reset();
  }
  return (
    <>
      <section className="footer" style={{background: '#1A3365'}}>
        <>
          <div className="container">
            <div className="row">
              <div className="col">
                <div style={{color: 'white', fontSize: '24px', fontWeight: '700' }}>
                  Connect Effortlessly. Grow Rapidly.
                </div>
                <div className="pt-3 pb-4" style={{color: '#12A9F6', fontSize: '18px', fontWeight: '500'}}>
                <a className='mail-support' href='mailto:support@financemutualapp.com'>support@financemutualapp.com</a>
                </div>
                <div className='footerterms d-flex align-items-center'>
                  <a href="/terms-conditions">
                    Terms & Conditions
                    {/* <img src="/assets/images/fb.svg" /> */}
                  </a>
                  <span style={{'color': 'white'}}>|</span>
                  <a href="/privacy-policy">
                    Privacy Policy
                  </a>
                  <span style={{'color': 'white'}}>|</span>
                  <a href="/contact-us">
                    Contact us
                  </a>
                  {/* <a href="https://twitter.com/FinanceMutual_">
                    <img className="px-2" src="/assets/images/twitter.svg" />
                  </a> */}
                </div>
              </div>
              <div className="col" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '0px 120px', alignItems: 'flex-end'}}>
                <div className="position-relative">
                  <div className="py-1" style={{color: 'white', fontSize: '20px', fontWeight: '600' }}>Join our newsletter</div>
                  <form className='support-form form' onSubmit={handleSubmit(onSubmit)}>
                      <div className='col form-group'>
                        {/* <label className='floating-label'>Your Email</label> */}
                        <input style={{minWidth: '350px', maxWidth: '350px'}} className="floating-input p-2" placeholder="Email address" {...register("email", {
                          required: true, maxLength: 100,
                          pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|org)$/,
                            message: 'Invalid email format, must include @ and end with com, in or org'
                          }
                        })
                        } />
                        <img onClick={() => handleSubmit(onSubmit)()} style={{position: 'absolute', right: '0', top: '50px', width: '30px', cursor: 'pointer'}} src="/assets/images/arrow-left.png" />
                        {errors.email && <p className='danger'>{errors.email.message}</p>}
                      </div>
                  </form>
                  <div className='' style={{ color: 'white' }}>
                    Copyright © 2025 <a href='https://financemutualapp.com' target='_blank' className='mail-support'>FinanceMutual App</a>. All Rights Reserved.
                  </div>
                  {/* <input style={{minWidth: '350px', maxWidth: '350px'}} className="floating-input p-2" placeholder="Email address" /> */}
                </div>
              </div>
            </div>
          </div>
        </>
      </section>
    </>
  )
};

export default Footer;
