import { FC, useEffect, useState } from 'react';
import Footer from '../footer/footer';
import './privacy-policy.scss';
import httpApiCall from '../../api.service';
import parse from 'html-react-parser';

interface PrivacyPolicyProps {}

const PrivacyPolicy: FC<PrivacyPolicyProps> = () => {

  const [htmlData , setTerms] = useState<string>('');
  
  useEffect(() => {
    callApi();
  }, [])

  const callApi = async () => {
    const data = {
      template_name: 'PRIVACY_POLICY_FINANCEMUTUAL_APP',
      type: 1
    }
    const response = await httpApiCall({
      type: "GET",
      api: "cms_details",
      body: data,
    });

    if (response.data && response.data.length && response.data[0].content) {
      setTerms(response.data[0].content);
    }
  }


  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center d-flex justify-content-between pt-5">
            <h1 className="heading-1"><b>Finance Mutual LLC Privacy Policy</b></h1>
            <p style={{ color: '#667085', fontWeight: 700 }}>Effective Date: August 15, 2024</p>
          </div>
        </div>
      </div>
      {htmlData && <div>{parse(htmlData)}</div>}
      {!htmlData &&
        <div className='shimmerContainer p-5'>
          <p className="shimmerGlobal mb-2" style={{ display: "block", width: "40%", height: "20px", background: "#EAEAF4", marginBottom: "1rem", }} ></p>
          <p className="shimmerGlobal mb-2" style={{ display: "block", width: "100%", height: "8px", background: "#EAEAF4", marginBottom: "1rem", }} ></p>
          <p className="shimmerGlobal mb-2" style={{ display: "block", width: "100%", height: "8px", background: "#EAEAF4", marginBottom: "1rem", }} ></p>
          <p className="shimmerGlobal mb-2" style={{ display: "block", width: "100%", height: "8px", background: "#EAEAF4", marginBottom: "1rem", }} ></p>
          <p className="shimmerGlobal" style={{ display: "block", width: "100%", height: "8px", background: "#EAEAF4", marginBottom: "3rem", }} ></p>


          <p className="shimmerGlobal mb-2" style={{ display: "block", width: "40%", height: "20px", background: "#EAEAF4", marginBottom: "1rem", }} ></p>
          <p className="shimmerGlobal mb-2" style={{ display: "block", width: "100%", height: "8px", background: "#EAEAF4", marginBottom: "1rem", }} ></p>
          <p className="shimmerGlobal mb-2" style={{ display: "block", width: "100%", height: "8px", background: "#EAEAF4", marginBottom: "1rem", }} ></p>
          <p className="shimmerGlobal mb-2" style={{ display: "block", width: "100%", height: "8px", background: "#EAEAF4", marginBottom: "1rem", }} ></p>
          <p className="shimmerGlobal" style={{ display: "block", width: "100%", height: "8px", background: "#EAEAF4", marginBottom: "3rem", }} ></p>


          <p className="shimmerGlobal mb-2" style={{ display: "block", width: "40%", height: "20px", background: "#EAEAF4", marginBottom: "1rem", }} ></p>
          <p className="shimmerGlobal mb-2" style={{ display: "block", width: "100%", height: "8px", background: "#EAEAF4", marginBottom: "1rem", }} ></p>
          <p className="shimmerGlobal mb-2" style={{ display: "block", width: "100%", height: "8px", background: "#EAEAF4", marginBottom: "1rem", }} ></p>
          <p className="shimmerGlobal mb-2" style={{ display: "block", width: "100%", height: "8px", background: "#EAEAF4", marginBottom: "1rem", }} ></p>
          <p className="shimmerGlobal" style={{ display: "block", width: "100%", height: "8px", background: "#EAEAF4", marginBottom: "3rem", }} ></p>
        </div>
      }
      {/* <section className="privacy-content">
        <div className="container">
          <div className="row">
            <div className="col-12 content-inner">
              <h3 className="sub-heading">Privacy Policy</h3>
              <p className="sub-heading">1. Our approach to privacy</p>
              <p>1.1 Finance Mutual LLC ("Finance Mutual LLC") is committed to protecting your privacy. This privacy policy sets out how we collect, process, transfer, share and use data that identifies or is associated with you ("personal information") and information regarding our use of cookies and similar technologies.</p>
              <p>1.2 Finance Mutual LLC operates a cloud-based legal practice management solution available via our websites (our "Websites") including at Financemutualapp.com and mobile application as well as other products and services that we make available (the "Finance Mutual LLC Service").</p>
              <p>1.3 This privacy policy applies to the Finance Mutual LLC Service.</p>
              <p>1.4 Before accessing or using the Finance Mutual LLC Service, please ensure that you have read and understood our collection, storage, use and disclosure of your personal information as described in this privacy policy. By accessing or using the Finance Mutual LLC Service, you are accepting and consenting to the practices described in this privacy policy.</p>




              <p className="sub-heading">2. Information We Collect About You and How We Collect It</p>
              <p><b> - What personal information is collected</b></p>

              <p>
                Below is the personal information we collect, which you voluntarily provide when interacting with us.
              </p>

              <ul className="">
                <li>
                  Information You Provide
                  <ul>
                    <li>
                      Account Registration: To create a Finance Mutual LLC
                      account, you may be required to provide your name, date
                      of birth, mailing address, email address, phone number,
                      and any additional information we may request to verify
                      your identity (e.g., Social Security number, driver's
                      license, or other government-issued ID).
                    </li>
                    <li>
                      Profile Details: We may collect additional information
                      you choose to include in your user profile, such as your
                      photo, occupation, or biography. Communications: We
                      collect information when you contact us for support,
                      provide feedback, or otherwise communicate with us. This
                      may include your name, email address, phone number, and
                      the content of your communications.
                    </li>
                    <li>
                      Communications: We collect information when you contact
                      us for support, provide feedback, or otherwise
                      communicate with us. This may include your name, email
                      address, phone number, and the content of your
                      communications.
                    </li>
                    <li>
                      Web Form Submissions: We collect information through a
                      web form, where we obtain the following details: full
                      name, email address, mobile number, and message.
                    </li>
                  </ul>
                </li>
                <li>
                  Information Collected Automatically
                  <ul>
                    <li>
                      Usage Data: Date and time of visits, pages viewed,
                      actions taken, referral URLs, and links clicked.
                    </li>
                    <li>
                      Device Data: IP address, device type, unique
                      identifiers, operating system, and browser type.
                    </li>
                    <li>
                      Identity Verification: To comply with laws regarding
                      fraud prevention, Anti-Money Laundering ("AML"), and
                      Know Your Client ("KYC") requirements.
                    </li>
                  </ul>
                </li>
                <li>
                  Information from Third Parties
                  <ul>
                    <li>
                      Identity Verification: To comply with laws regarding
                      fraud prevention, Anti-Money Laundering ("AML"), and
                      Know Your Client ("KYC") requirements.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                <b>- How We Collect Your Personal Information</b>
              </p>
              <p>
                We collect personal information through various means,
                including:
              </p>

              <ul>
                <li>
                  Direct User Input: When you register for an account,
                  complete your profile, or communicate with us.
                </li>
                <li>
                  Web Forms: When you fill out a form on our website,
                  providing details such as your full name, email address,
                  mobile number, and message.
                </li>
                <li>
                  Automated Data Collection: When you use the platform, we
                  collect information such as device details, browsing
                  activity, and location data through cookies, beacons, and
                  other tracking technologies.
                </li>
              </ul>

              <p>
                <b>- How We Use Your Personal Information</b>
              </p>
              <p>We use the collected data for the following purposes:</p>

              <ul>
                <li>
                  Account Creation and Verification: To verify your identity,
                  ensure compliance with AML/KYC regulations, and create and
                  maintain your account.
                </li>
                <li>
                  Communication: To respond to your inquiries, send
                  administrative or legal notices (including electronically),
                  and provide Platform updates.
                </li>
                <li>
                  Analytics and Service Improvement: To analyze usage trends
                  and to improve the content, functionality, and security of
                  the Platform.
                </li>
                <li>
                  Fraud Prevention and Security: To protect our business and
                  Platform users from fraudulent, unauthorized, or illegal
                  activity and to enforce our Terms of Use.
                </li>
                <li>
                  Legal and Regulatory Compliance: To comply with applicable
                  laws, regulations, and legal processes, including responding
                  to requests from government authorities
                </li>
              </ul>

              <p>
                <b>
                  - How and Why We Share Personal Information with Third
                  Parties
                </b>
              </p>
              <p>
                No mobile information will be shared with third
                parties/affiliates for marketing/promotional purposes.
                Information sharing to subcontractors in support services,
                such as customer service, is permitted. SMS consent is
                respected and not shared with any third parties or affiliates
                for marketing purposes.
              </p>

              <p>
                <b>- How We Use Your Information</b>
              </p>
              <p>
                We use information that we collect about you or that you
                provide to us for purposes including:
              </p>

              <ul>
                <li>
                  Account Creation and Verification: To verify your identity,
                  ensure compliance with AML/KYC regulations, and create and
                  maintain you account
                </li>
                <li>
                  Platform Functionality: To provide you with access to the
                  Platform.
                </li>
                <li>
                  Communication: To respond to your inquiries, send
                  administrative or legal notices (including electronically),
                  and provide Platform updates.
                </li>
              </ul>

              <p className="sub-heading">3. Disclosure of your personal information</p>
              <p>
                We respect your privacy and are committed to protecting your personal information. We want to assure you that we do not share, sell, rent, or disclose your personal information to any third parties for marketing or other purposes. We implement strict security measures to safeguard your data and ensure that it remains confidential. If you have any concerns or questions regarding your privacy, please contact us at +1 (888)-927-6680 or write to us at support@financemutualapp.com.
              </p>


              <p className="sub-heading">4. Storing and transferring your personal information</p>
              <p>4.1 Finance Mutual LLC has implemented administrative, technical, and physical safeguards to protect its and its customers' information. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Service, you are responsible for keeping this password confidential. Subscribers should not share their password with anyone.</p>
              <p>4.2 While no transmission of information via the internet is completely secure, we take reasonable measures to protect your personal information. We cannot guarantee the security of your personal information transmitted to our Website; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.</p>

              <p className="sub-heading">5. Retaining your information</p>
              <p>5.1 We will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of our legitimate business interests and satisfying any legal or reporting requirements.</p>
              <p>5.2 To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and the applicable legal requirements.</p>


              <p className="sub-heading">6. Your rights in respect of your personal information</p>
              <p>6.1 In accordance with applicable privacy law, you have the following rights in respect of your personal information that we hold:</p>
              <ul>
                <li>Right of access and portability. The right to obtain access to your personal information along with certain information, and to receive that personal information in a commonly used format and to have it ported to another data controller.</li>
                <li>Right to rectification. The right to obtain rectification of your personal information without undue delay where that personal information is inaccurate or incomplete.</li>
                <li>Right to erasure. The right to obtain the erasure of your personal information without undue delay in certain circumstances, such as where the personal information is no longer necessary in relation to the purposes for which it was collected or processed.</li>
                <li>Right to restriction. The right to obtain the restriction of the processing undertaken by us on your personal information in certain circumstances, such as where the accuracy of the personal information is contested by you or the sale of your personal information for a period enabling us to verify the accuracy of that personal information.</li>
                <li>Right to object. The right to object, on grounds relating to your particular situation, to the processing of your personal information, and to object to the processing of your personal information for direct marketing purposes, to the extent it is related to such direct marketing.</li>
                <li>Right to non-discrimination. The right to non-discrimination for exercising your rights as outlined in this policy. This includes, but is not limited to, denying you goods or services, charging you different prices for similar services, or providing a different level or quality of service.</li>
              </ul>
              <p>6.2 If you wish to exercise one of these rights, please contact us using the contact details at the end of this privacy policy. Upon request, we will provide you with information about whether we hold any of your personal information. We may request that you verify your identity prior to transferring personal information. You may access, correct or request deletion of your personal information by logging into your Finance Mutual LLC Service account. We will respond to your request within 30 days.</p>
              <p>6.3 Finance Mutual LLC does not sell personal information shared by you. Finance Mutual LLC has not sold personal information shared by you in the 12 months preceding the modification date for this policy. All use of personal information is done for the delivery, use, and improvement of the Service, as listed in 3.1.</p>

              <p className="sub-heading">7. Cookies and similar technologies</p>
              <p>7.1 Our Service uses cookies and similar technologies (collectively referred to as cookies) to distinguish you from other users of our Service. This helps us to provide you with good service. This helps us enhance and personalize your user experience, to monitor and improve our Website and services, and for other internal purposes. As is true of most websites, we gather certain information automatically. This information we may collect is described in detail in Annex 2.</p>
              <p>7.2 We use the following types of cookies:</p>
              <ul>
                <li>Strictly necessary cookies. These cookies are required for the essential operation of our Service such as to authenticate you and prevent fraudulent use.</li>
                <li>Analytical/performance cookies. These cookies allow us to recognize and count the number of visitors and to see how visitors move around our Service when they are using it. This helps us to improve the way our Service works, for example, by ensuring that you can find information easily.</li>
                <li>Functionality cookies. These cookies are used to recognize you when you return to our Service. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</li>
                <li>Targeting cookies. These cookies record your visit to our Service, the pages you have visited and the links you have followed. We will use this information to make our Service and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</li>
              </ul>
              <p>7.3 Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>
              <p>7.4 You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including strictly necessary cookies) you may not be able to access all or parts of our site.</p>
              <p>7.5 Social Media. Our Website includes social media features, such as Facebook Like button and widgets such as the Share button. These features may collect your IP address, which page you are visiting on our Website, and may set a cookie to enable the feature to function properly. Social media features and widgets are hosted on our site or by a third party, and your interactions with these features are governed by the privacy policy of the company providing it.</p>


              <p className="sub-heading">8. Links to third party sites</p>
              <p>8.1 The Finance Mutual LLC Service may, from time to time, contain links to and from third party websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for their policies. Please check the individual policies before you submit any information to those websites.</p>
              <p>8.2 Some of the pages on our Website may utilize framing techniques to serve content to/from our partners while preserving the look and feel of our Website. Please be aware that you are providing your personal information to these third parties and not to Finance Mutual LLC.</p>


              <p className="sub-heading">9. Our policy towards children</p>
              <p>9.1 The Finance Mutual LLC Service is not directed at persons under 18 and we do not intend to collect personal information from children under 18 in our capacity as a controller. If you become aware that a child has provided us with personal information without appropriate consent, then please contact us using the details below so that we can take the appropriate steps in accordance with our legal obligations and this privacy policy.</p>


              <p className="sub-heading">10. Changes to this policy</p>
              <p>10.1 We may update this privacy policy from time to time and so you should review this page periodically. When we change this privacy policy in a material way, we will update the "last modified" date at the end of this privacy policy. Changes to this privacy policy are effective when they are posted on this page.</p>


              <p className="sub-heading">11. Notice to you</p>
              <p>11.1 If we need to provide you with information about something, whether for legal, marketing or other business related purposes, we will select what we believe is the best way to get in contact with you. We will usually do this through email or by placing a notice on our Website. The fact that we may send notices to you will not stop you from being able to opt out of certain types of contact as described in this privacy policy.</p>


              <p className="sub-heading">12. Contacting us</p>
              <p>12.1 Any questions, comments, and requests regarding this privacy policy are welcome and should be addressed to our Legal Department at <a href="mailto:support@financemutualapp.com">support@financemutualapp.com</a>.</p>

              <p className="sub-heading">13. California Online Privacy Protection Act</p>
              <p>13.1 CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared.</p>
              <p>13.2 According to CalOPPA, we agree to the following:</p>
              <ul>
                <li>Users can visit our site anonymously.</li>
                <li>Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website.</li>
                <li>Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page specified above.</li>
                <li>You will be notified of any Privacy Policy changes on our Privacy Policy Page</li>
                <li>Can change your personal information by logging in to your account</li>
              </ul>
              <p className="sub-heading">Annex 1 - Personal information we collect</p>
              <p>All disclosures listed below are made with service providers only and done in accordance with Finance Mutual LLC's Terms of Service and Privacy Policy respectively.</p>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Category of personal information</th>
                      <th scope="col">How we use it</th>
                      <th scope="col">Legal basis for the processing</th>
                      <th scope="col">CCPA Categorization</th>
                      <th scope="col">Previous 12-month disclosures</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p><strong>Contact information and basic personal details.</strong> Such as your name, phone number, address, location, IP address, e-mail address and where applicable, professional details such as your bar membership number.</p>
                      </td>
                      <td>
                        <p>We use this information to communicate with you, including sending statements, news, alerts and marketing communications</p>
                        <p>We use this information to deal with inquiries and other requests made by or about you, including customer service issues, relating to the Finance Mutual LLC Service.Such communications may include direct mailing.</p>
                        <p>We use this information to operate, maintain and provide to you the features and functionality of the Finance Mutual LLC Service.</p>
                      </td>
                      <td>
                        <p>The processing is necessary for our legitimate interests, namely for marketing purposes, and for communicating with you effectively and responding to your queries.</p>
                        <p>The processing is necessary for the performance of a contract and to take steps prior to entering into a contract (namely our Terms of Service).The processing is necessary for the fulfillment of legal requirements, including the verification of the identity of customers.</p>
                      </td>
                      <td>
                        <p>Identifiers</p>
                      </td>
                      <td>
                        <p>Yes</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Email account username and password.</p>
                      </td>
                      <td>
                        <p>Where you have chosen to import contacts from your Outlook or other email account address book to invite them to become members of our Website, we collect the username and password for the email account you wish to import your contacts from.</p>
                      </td>
                      <td>
                        <p>The processing is necessary for the performance of a contract and to take steps prior to entering into a contract (namely our Terms of Service).</p>
                      </td>
                      <td>
                        <p>Identifiers</p>
                      </td>
                      <td>
                        <p>Yes for email account username</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Correspondence and comments. When you contact us directly, e.g. by email, phone, mail, or when you interact with customer service, we will record your comments and opinions.</p>
                      </td>
                      <td>
                        <p>To address your questions, issues, and concerns and resolve your customer service issues.</p>
                      </td>
                      <td>
                        <p>The processing is necessary for our legitimate interests, namely communicating with you effectively for the purposes of resolving your issues.</p>
                      </td>
                      <td>
                        <p>Audio, electronic, visual, thermal, olfactory, or similar information</p>
                      </td>
                      <td>
                        <p>Yes</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Payment information.Details such as your credit card or other financial information including credit scores obtained from credit reference agencies.</p>
                      </td>
                      <td>
                        <p>We use this information to facilitate payment through or for use of the Finance Mutual LLC Service, to assess your credit score and to detect and prevent fraud.</p>
                      </td>
                      <td>
                        <p>The processing for assessing your credit score and facilitating payment is necessary for the performance of our contract (namely our Terms of Service).The processing is necessary for our legitimate interests, namely the detection and prevention of fraud.</p>
                      </td>
                      <td>
                        <p>Personal Information (as defined by California Customer Records Statute)</p>
                      </td>
                      <td>
                        <p>Yes</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Recruiting details. Contact information and basic personal details (as set out above); professional details and employment information such as resume, references, LinkedIn profile.</p>
                      </td>
                      <td>
                        <p>We use this information to facilitate recruiting.</p>
                      </td>
                      <td>
                        <p>The processing is necessary for our legitimate interests, namely assessing your suitability for a role with Finance Mutual LLC.</p>
                      </td>
                      <td>
                        <p>Employment / Education Information</p>
                      </td>
                      <td>
                        <p>Yes</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>All personal information set out above.</p>
                      </td>
                      <td>
                        <p>We will use all the personal information we collect to operate, maintain and provide to you the features and functionality of the Finance Mutual LLC Service, to monitor and improve the Finance Mutual LLC Service, our Website and business, for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes, to keep the Website safe and secure and to help us develop new products and services.</p>
                      </td>
                      <td>
                        <p>The processing is necessary for our legitimate interest, namely to administer and improve the Finance Mutual LLC Service, our business and develop new services.</p>
                      </td>
                      <td>
                        <p>Commercial information</p>
                      </td>
                      <td>
                        <p>Yes</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className="sub-heading">Annex 2 - Personal information collected automatically</p>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Category of personal information</th>
                      <th scope="col">How we use it</th>
                      <th scope="col">Legal basis for the processing</th>
                      <th scope="col">CCPA Categorization</th>
                      <th scope="col">Previous 12-month disclosures</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>Information about how you access and use the Finance Mutual LLC Service. For example, the website from which you came and the website to which you are going when you leave our Website, your social media profiles, how frequently you access the Finance Mutual LLC Service, the time you access the Finance Mutual LLC Service and how long you use it for, whether you open emails or click the links contained in emails, whether you access the Finance Mutual LLC Service from multiple devices, and other actions you take on the Finance Mutual LLC Service. We also gather information, which may include Internet protocol (IP) addresses,\ browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data. </p>
                      </td>
                      <td>
                        <p>We use this information to:
                        </p><ul>
                          <li>conduct market analysis, monitor the Finance Mutual LLC Service and how it is used in order to and improve our business and help us develop new products and services;</li>
                          <li>Generate marketing leads and determine news, alerts and other products and services that may be of interest to you for marketing purposes.</li>
                        </ul>
                        <p></p>
                      </td>
                      <td>
                        <p>The processing is necessary for our legitimate interests, namely: to conduct relevant analysis to improve the Finance Mutual LLC Service generally and for marketing purposes.</p>
                      </td>
                      <td>
                        <p>Geolocation information, Inferences about personal preferences and attributes drawn from profiling, Internet activity</p>
                      </td>
                      <td>
                        <p>Yes</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Information about your device. We also collect information about the computer, tablet, smartphone or other electronic devices you use to connect to the Finance Mutual LLC Service. This information can include details about the type of device, unique device identifying numbers, operating systems, browsers, and applications connected to the Finance Mutual LLC Service through the device, your Internet service provider or mobile network, your IP address.</p>
                      </td>
                      <td>
                        <p>We use this information to:
                        </p><ul>
                          <li>enable the Finance Mutual LLC Service to be presented to you on your device; and</li>
                          <li>operate, maintain and provide to you the features and functionality of the Finance Mutual LLC Service.</li>
                        </ul>
                        <p></p>
                        <p>We use this information to monitor and improve the Finance Mutual LLC Service and business and to help us develop new products and services.</p>
                      </td>
                      <td>
                        <p>The processing is necessary for the performance of a contract and (namely our Terms of Service).The processing is necessary for our legitimate interests, namely: to tailor the Finance Mutual LLC Service to the user and to improve the Finance Mutual LLC Service generally.</p>
                      </td>
                      <td>
                        <p>Internet or other electronic network activity information</p>
                      </td>
                      <td>
                        <p>Yes</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='privacy-policy-sms'>
                <p className="sub-heading">14.Privacy Policy for SMS</p>
                <p>SMS consent and Phone number won't be shared with any third party and affiliate company for marketing purposes.</p>
                <p>Users can manage their preferences any time by replying: </p>

                <ul>
                  <li>HELP for assistance. If you are experiencing any issues, you can reply with the keyword HELP. Or, you can get help directly from us at <b>+1 (888)927-6680</b> or write to us at <b> support@financemutualapp.com.</b></li>
                  <li>STOP to opt-out of receiving further SMS messages. After texting “STOP,” you may receive one final confirmation message stating that you have successfully unsubscribed. You will no longer receive SMS messages from that particular campaign unless you opt in again.</li>
                </ul>


                <p className="sub-heading">15.California Privacy Disclosures</p>

                <p>If you are a California resident, you may have additional rights under the CCPA/CPRA, including:</p>

                <ul>
                  <li>Right to Know: The categories of personal information we collect and how we use it.</li>
                  <li>Right to Delete: Request deletion of your personal information, with certain exceptions.</li>
                  <li>Right to Opt Out of Sale: We do not sell your personal information with any third party and affiliate company for marketing purposes.</li>
                  <li>Right to Non-Discrimination: We will not discriminate against you for exercising these rights</li>
                </ul>
                <p>
                  For more information on these rights and how to exercise them, please see "Your Choices and Rights" above or contact us at <b>+1 (888)-927-6680</b> or write to us at <b>support@financemutualapp.com.</b> </p>


                <p className="sub-heading">16.Data Retention</p>
                <p>We retain your personal information only as long as is necessary to fulfill the purposes for which we collected it, including to satisfy legal, accounting, or reporting requirements. Retention periods may vary depending on:</p>

                <ul>
                  <li>Statutory obligations under applicable laws and regulations.</li>
                  <li>Business needs for fraud prevention, record-keeping, or dispute resolution.</li>
                  <li>Other legitimate reasons consistent with this Policy.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </>
  )
};

export default PrivacyPolicy;
